<template>
    <div class="flex" style="flex-direction: column; height: 100%">
        <sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
            <el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
                <el-row class="flex-1">
                    <el-col :span="6">
                        <el-form-item label="查看企业：">
                            <el-select v-model="searchParm.entId" clearable :popper-append-to-body="false"
                                placeholder="请选择" @change="changeEnt">
                                <el-option v-for="item in ents" :key="item.abbr" :label="item.abbr"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="测点类型：">
                            <el-select v-model="measTypeId" clearable :popper-append-to-body="false" placeholder="请选择测点类型"
                                @change="measChange">
                                <el-option v-for="item in measType" :key="item.id" :label="item.value"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="绑定状态：">
                            <el-radio-group v-model="searchParm.bangdingStatus" @input="list()">
                                <el-radio :label="0">已绑定</el-radio>
                                <el-radio :label="1">未绑定</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                </el-row>
                <div>
                    <el-button type="primary" @click="list()">查询</el-button>
                    <el-button plain @click="clearSearch()">重置</el-button>
                </div>
            </el-form>
        </sm-card>
        <div class="tableModule">
            <div class="title">
                <div class="title-left">
                    <i class="el-icon-s-operation"></i>
                    <span>数据列表</span>
                </div>
                <div class="title-right"><el-button type="primary" size="medium" @click="insert()"
                        style="margin-right: 8px">新增绑定</el-button></div>
            </div>
            <div class="tableContainer">
                <div class="table" ref="tableContainer">
                    <div>
                        <el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
                            :header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
                            style="margin-bottom: 20px">
                            <el-table-column prop="id" align="center" label="绑定id" show-overflow-tooltip></el-table-column>
                            <el-table-column align="center" label="绑定时间" show-overflow-tooltip>
                            	<template slot-scope="scope">
                            		{{scope.row.createTime?scope.row.createTime:scope.row.create_time}}
                            	</template>
                            </el-table-column>
                            <el-table-column prop="createUser" align="center" label="绑定账号" show-overflow-tooltip>
                            	<template slot-scope="scope">
                            		{{scope.row.createUser?scope.row.createUser:scope.row.create_user}}
                            	</template>
                            </el-table-column>
							<el-table-column prop="entName" align="center" label="企业名称"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="measId" align="center" label="测点id"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="measName" align="center" label="测点名称"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="sensorId" align="center" label="	智寰传感器id"
                                show-overflow-tooltip></el-table-column>
                            <el-table-column prop="sensorName" align="center" label="智寰传感器名称"
                                show-overflow-tooltip></el-table-column>

                            <el-table-column prop="sensorName" align="center" label="测点类型" show-overflow-tooltip>
                                <template>
                                    <div>{{ measType[measTypeId].value }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" align="center" label="操作" width="230">
                                <template slot-scope="scope">
                                    <el-button v-if="searchParm.bangdingStatus != 1" type="text"
                                        @click="remove(scope.row)">解除绑定</el-button>
                                    <el-button v-if="searchParm.bangdingStatus == 1" type="text"
                                        @click="insert(scope.row)">去绑定</el-button>
                                    <el-button v-if="scope.row.entId == '12c3dc91b6644961ffe3ad91ecdb6539'&&searchParm.bangdingStatus != 1" type="text"
                                        @click="open(scope.row)">{{ scope.row.collectData == 1 ? '开启' : '关闭' }}</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination style="float: right" @current-change="handleCurrentChange"
                        :current-page.sync="searchParm.page" :page-size="searchParm.pageSize"
                        layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
                </div>
            </div>
        </div>
        <insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
    </div>
</template>
<script>
import smCard from '@c/smCard.vue';
import insert from './insert.vue';
export default {
    components: {
        smCard,
        insert
    },
    data() {
        return {
            tableHeight: 0,
            tableData: {
                records: []
            },
            searchParm: {
                pageSize: 20,
                page: 1,
                bangdingStatus: 0,
            },
            showInsertDialog: false,
            insertObj: {},
            showEditDialog: false,
            editObj: {},
            showCopyInsertDialog: false,
            copyInsertObj: {},

            ents: [],
            measType: [{
                id: 0,
                value: '振动'
            }, {
                id: 1,
                value: '温度'
            }, {
                id: 2,
                value: '油液'
            }, {
                id: 3,
                value: '转速'
            }],
            measTypeId: 0
        };
    },
    created() {
        this.getEnts();
    },
    methods: {
        //自适应表格高度
        calHeight() {
            this.$nextTick(() => {
                const rect = this.$refs.tableContainer.getBoundingClientRect();
                this.tableHeight = rect.height;
            });
        },
        //获取企业列表
        getEnts() {
            this.$get('/backend-api/sys/ent/list', {
                size: 99
            }).then(res => {
                if (res.code == 1000) {
                    this.ents = res.data.records;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        measChange(e) {
            this.tableData = [];
            this.list();
        },
		changeEnt(e){
			this.tableData = [];
			this.list();
		},
        //清空搜索
        clearSearch() {
            this.searchParm = {
                pageSize: 20,
                page: 1,
                bangdingStatus: 0,
            };
            this.measTypeId = 0;
            this.list();
        },
        //列表数据
        list() {
            // /data-api/job/zhihuan/getRpmList  转速

            ///backend-api/eqp/set/unit/meas/zhihuan/list   振动

            ///data-api/job/zhihuan/getTempList   温度


            let url = ''


            if (this.measTypeId == 0) {
                url = '/backend-api/eqp/set/unit/meas/zhihuan/list'
            } else if (this.measTypeId == 1) {
                url = '/data-api/job/zhihuan/getTempList'
            } else if (this.measTypeId == 2) {
                url = '/data-api/job/zhihuan/getOilList'
            } else if (this.measTypeId == 3) {
                url = '/data-api/job/zhihuan/getRpmList'
            }

            if (this.measTypeId == 0) {
                let param = {
                    size: this.searchParm.pageSize,
                    current: this.searchParm.page,
                    entId: this.searchParm.entId,
                    bangdingStatus: this.searchParm.bangdingStatus
                }
				if(!this.searchParm.entId){
					delete param.entId
				}
                this.$get(url, param).then(res => {
                    if (res.code == 1000) {
                        this.tableData = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$postJson(url, this.searchParm).then(res => {
                    if (res.code == 1000) {
                        this.tableData = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        //打开新增弹窗
        insert(row) {
            if (row) {
                let obj = {
                    type:3,
                    measTypeId:this.measTypeId
                }
                this.insertObj = {...row,...obj}
                console.log(this.insertObj)
            } else {
                this.insertObj['type'] = 1;
                this.insertObj['measTypeId'] = this.measTypeId;
            }
            this.showInsertDialog = true;
        },
        //打开
        open(row) {
            this.$postJson('backend-api/eqp/set/unit/meas/zhihuan/updateCollect', {
                id: row.id,
                collectData: row.collectData == 1 ? 0 : 1
            }).then(res => {
                if (res.code == 1000) {
                    this.$message.success(res.msg);
                    //刷新表格
                    this.list();
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //打开编辑弹窗
        edit(row) {
            this.editObj = row;
            this.showEditDialog = true;
        },
        //分页选择页面回调
        handleCurrentChange(val) {
            this.searchParm.page = val;
            this.list();
        },
        //删除
        remove(row) {
            this.$confirm('是否确认删除？', '删除绑定', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    let url;
                    if (this.measTypeId == 0) {
                        url = '/backend-api/eqp/set/unit/meas/zhihuan/delete'
                    } else if (this.measTypeId == 1) {
                        url = '/data-api/job/zhihuan/deleteTempBind'
                    } else if (this.measTypeId == 2) {
                        url = '/data-api/job/zhihuan/deleteOilBind'
                    } else if (this.measTypeId == 3) {
                        url = '/data-api/job/zhihuan/deleteBind'
                    }



                    this.$post(url, {
                        id: row.id
                    }).then(res => {
                        if (res.code == 1000) {
                            this.$message.success(res.msg);
                            //刷新表格
                            this.list();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        }
    },
    mounted() {
        this.list();
        this.calHeight();
        window.addEventListener('resize', this.calHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calHeight);
    }
};
</script>
<style lang="scss" scoped>@import '@style/userCenter.scss';</style>