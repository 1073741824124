<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="测点名称：" prop="name">
							<el-cascader v-if="dialogObj.type != 3" v-model="formData.measId" :options="treeData"
								:props="{
									value: 'id',
									label: 'name',
									children: 'children',
									emitPath: false
								}"></el-cascader>

							<div v-else>{{title}}</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="智寰传感器：" prop="brand">
							<el-cascader v-model="formData.sensorId" :options="treeData2" :props="{
									value: 'id',
									label: 'name',
									children: 'children',
									emitPath: false
								}" @change="handleChange" filterable></el-cascader>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-form-item label="测点类型：">
						<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
							<el-checkbox disabled v-for="city in cities" :label="city"
								:key="city">{{city}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	var regu = /^[0-9]+\.?[0-9]*$/;
	import {
		dialogMixin
	} from '@m/dialogMixin';
	import configApi from '@config/configApi';
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				formData: {},
				treeData: [],
				treeData2: [],
				checkedCities: ['振动'],
				cities: ['振动', '温度', '油液', '转速'],
				isIndeterminate: true,
				title: '新增绑定',
			};
		},
		props: {},
		watch: {},
		methods: {
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
			//打开弹窗
			open() {
				if (this.dialogObj.measTypeId == 0) {
					this.title = '振动测点绑定'
					this.checkedCities = ['振动']
				} else if (this.dialogObj.measTypeId == 1) {
					this.title = '温度测点绑定'
					this.checkedCities = ['温度']
				} else if (this.dialogObj
					.measTypeId == 2) {
					this.title = '油液测点绑定'
					this.checkedCities = ['油液']
				} else if (this.dialogObj.measTypeId == 3) {
					this.title = '转速测点绑定'
					this.checkedCities = ['转速']
				}
				this.formData = {};
				if (this.dialogObj.type == 2) {
					this.get();
				} else if (this.dialogObj.type == 3) {
					this.title = this.dialogObj.measName + '绑定'
					this.formData.measId = this.dialogObj.measId;
				}
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});
				this.getmeasList();
				this.getData();
			},
			getmeasList() {
				this.$get('/backend-api/eqp/set/meas/list?meas_include=1&meas_suffix=1').then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData = res.data;
				});
			},

			getData() {
				this.$get('/backend-api/eqp/set/unit/meas/zhihuan/zhihuan/sensor/list').then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData2 = res.data;
				});
			},

			handleChange(value) {
				this.formData.sensorName = this.recurse(this.treeData2, value)
			},

			get() {
				// this.$get('/backend-api/comp/type/bearing/get', {
				// 	id: this.dialogObj.row.id
				// }).then(res => {
				// 	if (res.code == 1000) {
				// 		this.formData = res.data;
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// });
			},
			recurse(n, d) {
				// debugger
				for (let i = 0; i < n.length; i++) {
					if (n[i].id == d) {
						return n[i].name;
					} else {
						for (let e = 0; e < n[i].children.length; e++) {
							if (n[i].children[e].id == d) {
								return n[i].children[e].name;
							}
						}
					}
				}
			},
			//提交表单
			submit() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.loading = true;
						let url;

						if (this.dialogObj.measTypeId == 0) {
							this.$postData('/backend-api/eqp/set/unit/meas/zhihuan/save', this.formData).then(
								res => {
									if (res.code == 1000) {
										this.loading = false;
										this.$message.success(res.msg);
										this.$parent.list();
										this.close();
									} else {
										this.loading = false;
										this.$message.error(res.msg);
									}
								});
							return
						} else if (this.dialogObj.measTypeId == 1) {
							url = '/data-api/job/zhihuan/getTempSensorIdBing'
						} else if (this.dialogObj.measTypeId == 2) {
							url = '/data-api/job/zhihuan/getOilSensorIdBing'
						} else if (this.dialogObj.measTypeId == 3) {
							url = '/data-api/job/zhihuan/getSensorIdBing'
						}

						this.$post(url, this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.dialog {
		::v-deep .cascader {
			display: block;
		}

		::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
			background-color: #409EFF;
			border-color: #409EFF
		}

		::v-deep.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
			border-color: #ffffff;
		}

	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep input[type='number'] {
		-moz-appearance: textfield;
	}
</style>